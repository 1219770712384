  // Temporary icon fix
  // TODO: remove this once the WETH icons are fixed on the backend
  export const handleMissingWethImage = (address: string, proxyApi?: string) => {
    const addressesMissingImageUrls = [
      '0x80F30227bd7A2b7128e7C598e3f892ED5891f3b9', // Cloud
      '0x993232978869f1772Fb5D282Fdbcb0f6C5ef744D' // Boss
    ]

    // If the image is missing, use the default WETH icon from a chain that has it set correctly
    if (addressesMissingImageUrls.includes(address)) {
      return `${process.env.NEXT_PUBLIC_PROXY_URL}${proxyApi}/redirect/currency/0x48A9B22b80F566E88f0f1DcC90Ea15A8A3bAE8a4/icon/v1`
    }

    return null
  }
