import React, { FC } from 'react'
import { styled } from '../../stitches.config'
import { StyledComponent } from '@stitches/react/types/styled-component'
import { zeroAddress } from 'viem'
import { useMarketplaceChain } from 'hooks'
import supportedChains, { DefaultChain } from 'utils/chains'
import { handleMissingWethImage } from 'utils/helpers/handleMissingWethIcon'

type Props = {
  address: string
  chainId?: number
} & Parameters<StyledComponent>['0']

const StyledImg = styled('img', {})

const CryptoCurrencyIcon: FC<Props> = ({
  address = zeroAddress,
  chainId,
  css,
}) => {
  const { proxyApi } = useMarketplaceChain()
  const chain = supportedChains.find((chain) => chain.id === chainId)

  const [imageError, setImageError] = React.useState(false)

  const backupImg = handleMissingWethImage(address, chain?.proxyApi ?? proxyApi)

  return (
    <StyledImg
      src={backupImg || `${process.env.NEXT_PUBLIC_PROXY_URL}${chain?.proxyApi ?? proxyApi}/redirect/currency/${address}/icon/v1`}
      css={{...css, display: imageError ? 'none' : 'block'}}
      onError={() => setImageError(true)}
    />
  )
}

export default CryptoCurrencyIcon
